import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueRouter from 'vue-router'
import router from './router'

Vue.prototype.$format = function(time, format) {
  var t = new Date(time)
  return format.replace(/yyyy|MM|dd|HH|mm|ss/g, function(a) {
    var tf = function(i) { return (i < 10 ? '0' : '') + i }
    switch (a) {
      case 'yyyy':
        return tf(t.getFullYear())
      case 'MM':
        return tf(t.getMonth() + 1)
      case 'mm':
        return tf(t.getMinutes())
      case 'dd':
        return tf(t.getDate())
      case 'HH':
        return tf(t.getHours())
      case 'ss':
        return tf(t.getSeconds())
    }
  })
}

Vue.config.productionTip = false

Vue.use(ElementUI)
Vue.use(VueRouter)



new Vue({
  router: router,
  render: h => h(App),
}).$mount('#app')

// router.beforeEach((to, from, next) => {
//   if (to.path != '/goboLogin') {
//     if(sessionStorage.getItem('gobo')){
//       next()
//     }else{
//       alert('未登录！请先进行账号密码登录！')
//     }
//   }
// })