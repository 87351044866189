
import VueRouter from "vue-router"
import Vue from 'vue'


Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  base: '/gobo/',
  routes:[
    {
      path:'/',
      redirect: '/goboLogin'
    },
    {
      name: 'goboLogin',
      path: '/goboLogin',
      component: () => import('@/pages/goboLogin')
    },
    {
      name: 'tableindex',
      path: '/tableIndex',
      component: () => import('@/components/tableIndex'),
      beforeEnter(to,from,next){
        if (to.path == '/tableIndex') {
          if(sessionStorage.getItem('gobo')){
            next()
          }else{
            // this.$message.error('未登录，请登录账号密码！')
            next(from.path)
          }
        }
      }
    },
    // {
    //   name:'tabledetail',
    //   path: '/tableDetail',
    //   component: () => import('@/components/tableDetail'),
    //   beforeEnter(to,from,next){
    //     if (to.path == '/tableDetail') {
    //       if (to.path == '/tableDetail') {
    //         if(sessionStorage.getItem('gobo')){
    //           next()
    //         }else{
    //           // this.$message.error('未登录，请登录账号密码！')
    //           alert('未登录，请先登录账号密码！')
    //           next(from.path)
    //         }
    //       }
    //     }
    //   }
    // }
  ]
})



